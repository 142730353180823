import React from 'react'
import AboutHero from './AboutHero'
import Header from '../Comman/Header'
import Footer from '../Comman/Footer'
import Statustics from '../Home/Statustics'
import OurMisson from './OurMisson'
import Empowering from './Empowering'
import Faqs from '../Home/Faqs'

export default function About() {
    return (
        <>
            <div className="sub-banner-section-outer position-relative">
                <Header />
                <AboutHero />
            </div>
            <Statustics />
            <OurMisson />
            <Empowering />
            <Faqs />
            <Footer />
        </>
    )
}
