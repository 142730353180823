import React from 'react'

export default function Faqs() {
    return (
        <>

            <section className="combo_section">
                {/* FAQ'S SECTION */}
                <div className="accordian-section">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="heading position-relative text-center">
                                    <h6>FAQ’s</h6>
                                    <h2>Frequently Asked Questions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-up">
                                <div className="about-service-section-text accordian-section-inner-text position-relative">
                                    <div className="accordian-inner">
                                        <div id="accordion1">
                                            <div className="accordion-card">
                                                <div className="card-header" id="headingFour">
                                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        What is Oreva?
                                                    </button>
                                                </div>
                                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour">
                                                    <div className="card-body">
                                                        <p className="text-size-16 text-left mb-0 p-0">Oreva is a community of traders, investors and leaders who work together collectively to expand the crypto ecosystem while unlocking mutual profitability. </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-card">
                                                <div className="card-header" id="headingFive">
                                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Who could join Oreva?
                                                    </button>
                                                </div>
                                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive">
                                                    <div className="card-body">
                                                        <p className="text-size-16 text-left mb-0 p-0">Oreva is open for all and it can be joined by anyone.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-card">
                                                <div className="card-header" id="headingSix">
                                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                        How to join Oreva?
                                                    </button>
                                                </div>
                                                <div id="collapseSix" className="collapse" aria-labelledby="headingSix">
                                                    <div className="card-body">
                                                        <p className="text-size-16 text-left mb-0 p-0">Joining Oreva is an easy process. All you have to do is create an account and choose your joining package.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12" data-aos="fade-right">
                                <div className="about-service-section-text position-relative">
                                    <div className="accordian-inner">
                                        <div id="accordion2">
                                            <div className="accordion-card">
                                                <div className="card-header" id="headingSeven">
                                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                        What does the Oreva community do?
                                                    </button>
                                                </div>
                                                <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven">
                                                    <div className="card-body">
                                                        <p className="text-size-16 text-left mb-0 p-0">The Oreva community shares knowledge and experience while employing trades across the markets of stocks, cryptocurrency and forex.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-card">
                                                <div className="card-header" id="headingEight">
                                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">

                                                        What is the OREVA token?

                                                    </button>
                                                </div>
                                                <div id="collapseEight" className="collapse" aria-labelledby="headingEight">
                                                    <div className="card-body">
                                                        <p className="text-size-16 text-left mb-0 p-0">OREVA token is the native currency for Oreva community and ecosystem which is used as gateway token, transactions and payments.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-card">
                                                <div className="card-header" id="headingNine">
                                                    <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                        Where OREVA token is used?
                                                    </button>
                                                </div>
                                                <div id="collapseNine" className="collapse" aria-labelledby="headingNine">
                                                    <div className="card-body">
                                                        <p className="text-size-16 text-left mb-0 p-0">OREVA token is used as gateway token, transactions and payments across the Oreva ecosystem platforms.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <figure className="mb-0 faq_right_shape position-absolute top_bottom_shape">
                        <img src="assets/images/faq_right_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
                {/* CLIENT LOGO'S SECTION */}
                <div className="client_logos_section">
                    <div className="container">
                        <div className="row" data-aos="fade-up">
                            <div className="col-lg-1 col-md-1 col-sm-6 col-12 d-md-block d-none" />
                            <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                                <figure className="logo_image1 mb-0">
                                    <img src="assets/images/metamask.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                                <figure className="logo_image2 mb-0">
                                    <img src="assets/images/trustwallet.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                                <figure className="logo_image3 mb-0">
                                    <img src="assets/images/bsc_scan.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                                <figure className="logo_image4 mb-0">
                                    <img src="assets/images/coin_iournal.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-4 col-6">
                                <figure className="logo_image5 mb-0">
                                    <img src="assets/images/coin_marketcap.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-6 col-12 d-md-block d-none" />
                        </div>
                    </div>
                </div>
                {/* GET STARTED SECTION */}
                <div className="get_started_section">
                    <div className="container">
                        <div className="get_started_box">
                            <div className="row">
                                <div className="col-lg-5 col-md-5 col-sm-12 col-12 order-md-1 order-2">
                                    <div className="get_started_box_image">
                                        <figure className="mb-0 left_right_shape">
                                            <img src="assets/images/join_oreva.png" alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-md-7 col-sm-12 col-12 order-md-2 order-1">
                                    <div className="get_started_box_content" data-aos="fade-right">
                                        <h6>GEt Started</h6>
                                        <h2>Join Oreva Community</h2>
                                        <div className="middle-portion">
                                            <div className="row">
                                                <div className="col-lg-12 col-md-4 col-sm-6 col-xs-12">
                                                    <div className="about_col">
                                                        <ul className="list-unstyled mb-0">
                                                            <li className="icons hover-effect"><a href="#"><i className="fa-brands fa-facebook-f" aria-hidden="true" /></a></li>
                                                            <li className="icons hover-effect"><a href="#"><i className="fa-brands fa-twitter" aria-hidden="true" /></a></li>
                                                            <li className="icons hover-effect"><a href="#"><i className="fa-brands fa-linkedin-in" aria-hidden="true" /></a></li>
                                                            <li className="icons hover-effect"><a href="#"><i className="fa-brands fa-instagram" aria-hidden="true" /></a></li>
                                                            <li className="icons hover-effect"><a href="#"><i className="fa-brands fa-telegram" aria-hidden="true" /></a></li>
                                                            <li className="icons hover-effect"><a href="#"><i className="fa-brands fa-github mr-0" aria-hidden="true" /></a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <figure className="get_started_shape mb-0 left_right_shape">
                            <img src="assets/images/get_started_shape.png" alt="" className="img-fluid" />
                        </figure>
                    </div>
                </div>
            </section>

        </>
    )
}
