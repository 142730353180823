import React from 'react'
import Header from '../Comman/Header'
import Statustics from './Statustics'
import About from './About'
import Service from './Service'
import Hero from './Hero'
import WhoWeAre from './WhoWeAre'
import Faqs from './Faqs'
import Footer from '../Comman/Footer'
import Tokenomics from './Tokenomics'
import Roadmap from './Roadmap'
import Governance from './Governance'
import Community from './Community'
import Advantages from './Advantages'

export default function Index() {
    return (
        <>
            <div className="banner-section-outer position-relative" style={{backgroundImage:`url("../assets/images/hero_section_img.png")`}}>
                {/* <video muted autoPlay loop id="myVideo" className="video">
                    <source src="assets/images/hero_01.mp4" type="video/mp4" />
                </video> */}
                <Header />
                <Hero />
            </div>
            <Statustics />
            <Service />
            <About />
            <Tokenomics />
            <Roadmap />
            <WhoWeAre />
            <Governance/>
            <Community/>
            <Advantages/>
            <Faqs />
            <Footer />
        </>
    )
}
