import React from 'react'

export default function OurMisson() {
    return (
        <>
            <section className="mission_vision_section position-relative">
                <div className="container">
                    <div className="row position-relative" data-aos="fade-up">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="mission_vision_box hover-effect">
                                <figure>
                                    <img src="assets/images/our_mission_image.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h4>Mission </h4>
                                <p className="mb-0">Oreva’s mission is to leverage splitted expertise of community members to spread adoption of cryptocurrency and blockchain technology.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div className="mission_vision_box hover-effect">
                                <figure>
                                    <img src="assets/images/our_vision_image.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h4>Vision</h4>
                                <p className="mb-0">The vision of Oreva is forming a global community of investors, traders and leaders where each person contributes to the success of every other member.
                                </p>
                            </div>
                        </div>
                        <figure className="mission_vision_background_shape mb-0 position-absolute left_right_shape">
                            <img src="assets/images/mission_vision_background_shape.png" alt="" className="img-fluid" />
                        </figure>
                    </div>
                    <figure className="mission_vision_left_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/mission_vision_left_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
