import React from 'react'

export default function Product() {
    return (
        <>
            <section className="services_section services_service_section position-relative pt-0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="services_content">
                                <h6>Oreva Ecosystem</h6>
                                <h2>Services We Can Help You With</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12 d-md-block d-none" />
                    </div>
                    <div className="row position-relative">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box hover-effect">
                                <figure>
                                    <img src="assets/images/chain.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Oreva Chain</h5>
                                <p>The layer 1 blockchain network that offers seamless transactions while enabling lowest cost, vastly secure and superfast transfers compared to other blockchains.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box2 hover-effect">
                                <figure>
                                    <img src="assets/images/dex.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>
                                    Oreva DEX
                                </h5>
                                <p>Serves as native decentralized exchange for swapping assets on Oreva Chain. It allow users to trade assets without the need of KYC or any identity verification.

                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box3 hover-effect">
                                <figure>
                                    <img src="assets/images/scan.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>
                                    OrevaScan
                                </h5>
                                <p>Block explorer is essential for every Blockchain and OrevaScan fulfills this need by allowing to explore blocks, transactions and other data on the Oreva Chain. </p>

                            </div>
                        </div>
                    </div>
                    <div className="row position-relative" data-aos="fade-up">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box hover-effect">
                                <figure>
                                    <img src="assets/images/exchange.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Oreva Exchange
                                </h5>
                                <p>The native crypto exchange platform that enables trading of cryptocurrencies with fiat, stablecoins or other cryptocurrencies like BTC and ETH.
                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box2 hover-effect">
                                <figure>
                                    <img src="assets/images/nft.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>
                                    Oreva Mint
                                </h5>
                                <p>Mint is an exclusive digital marketplace for designing, minting & trading a wide range of  non-fungible tokens (NFTs) and collections.

                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box3 hover-effect">
                                <figure>
                                    <img src="assets/images/bridge.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Oreva Bridge
                                </h5>
                                <p>Oreva Bridge is a cross-chain blockchain bridge that enables inter-chain transactions between Oreva Chain & other multiple blockchain networks.

                                </p>

                            </div>
                        </div>

                     
                    </div>
                    <div className="row position-relative">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box hover-effect">
                                <figure>
                                    <img src="assets/images/daily.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Oreva Daily
                                </h5>
                                <p>Oreva Daily is a news and media platform for sharing market updates, events and other information in the blockchain and cryptocurrency industry.

                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box2 hover-effect">
                                <figure>
                                    <img src="assets/images/connect.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Oreva Connect
                                </h5>
                                <p>Connect is a blockchain-based social media application where users can connect with their friends online to share pictures, videos and blogs.

                                </p>

                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box3 hover-effect">
                                <figure>
                                    <img src="assets/images/wallet.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Oreva Wallet
                                </h5>
                                <p>Oreva Wallet is a non-custodial cryptocurrency wallet that allows sending, receiving and storing OREVA alongside hundreds of other cryptocurrencies.
                                </p>

                            </div>
                        </div>
                        <figure className="services_background_shape mb-0 position-absolute left_right_shape">
                            <img src="assets/images/services_background_shape.png" alt="" className="img-fluid" />
                        </figure>
                    </div>
                    <figure className="services_right_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/services_right_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
