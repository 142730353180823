import React from 'react'
import Slider from "react-slick";

export default function Roadmap() {
    var settings = {
        dots: false,
        arrows: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 2,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <section className="testimonials_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="testimonials_content">
                                <h6>Testimonials</h6>
                                <h2>Hear it From Our Clients</h2>
                            </div>
                        </div>
                    </div>
                        <Slider {...settings}>
                            <div className="owl-carousel owl-theme">
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image1.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H1 2024</p>
                                            </div>
                                            <ul>
                                                <li className="testimonials_paragraph mb-0">
                                                    <h6 className='person_name'> Market Research</h6>
                                                    Conducting market research & the scope of development
                                                </li>
                                                <li className="testimonials_paragraph mb-0">
                                                    <h6 className='person_name'> Strategy & Development</h6>
                                                    Crafting market strategy & development plan
                                                </li>
                                                <li className="testimonials_paragraph mb-0">
                                                    <h6 className='person_name'>OREVA Token Launch</h6>
                                                    Deployment of OREVA token for initial building community
                                                </li >
                                                <li className="testimonials_paragraph mb-0">
                                                    <h6 className='person_name'>Community Building</h6>
                                                    Building community while gathering people around the globe
                                                </li>
                                            </ul>
                                        </div>
                                        {/* <p className="testimonials_paragraph mb-0"><span>“</span>Quisquam est, qui dolorem ipsum quia dolor sit amet cone aetur, adipisci velit, sed quia non numquam eius modi temor incidunt ut labore et dolore magnam.<span>”</span></p> */}
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image2.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H2 2024</p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Marketing Campaigns
                                                </h6>
                                                Employing growth strategy & execution of marketing campaigns
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Partnerships & Collaborations</h6>
                                                Forming partnerships & collaborations with market players across different sector
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Community Meetup
                                                </h6>
                                                Holding the first major community meet-up where everyone meet & greet

                                            </li >
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Exchange Listing
                                                </h6>
                                                Listing OREVA token on leading crypto exchange

                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image1.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H1 2025</p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Payment Integrations
                                                </h6>
                                                Integration of OREVA to retail & business payment applications
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Launch on DEX
                                                </h6>
                                                Listing OREVA token on a leading decentralized exchange

                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>DEX Liquidity Pools</h6>
                                                Deploying liquidity for OREVA trading on decentralized exchanges
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Launch of Automated-trading bot </h6>
                                                Launching the smart-contract based automated trading bot on DEXs
                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image2.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H2 2025</p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Development Partnerships
                                                </h6>
                                                Onboarding companies for development resources

                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> KOLs & Influencers Onboarding
                                                </h6>
                                                Crafting market strategy & development plan
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Oreva Chain Development
                                                </h6>
                                                Starting research & development blueprint of Oreva Chain


                                            </li >
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Pre-launch Testnet Campaigns</h6>
                                                Gathering Node operators & stalking providers for Oreva Testnet

                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image1.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H1 2026
                                                </p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Testnet Launch</h6>
                                                Roll-out of the first iteration of Oreva Chain as testnet
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Testing & Performance Analysis
                                                </h6>
                                                Oreca testnet performance analysis & penetration testing
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Oreva Scan Going Live
                                                </h6>
                                                The blockchain explorer going live for testnet
                                            </li >
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Crypto Web Wallet (Desktop)
                                                </h6>
                                                Launching the native Oreva Web wallet
                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image2.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H2 2026
                                                </p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Oreva Mainnet Launch</h6>
                                                Oreva blockchain going live for the first time in public

                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Integrations with Exchange</h6>
                                                Integrating Oreva to leading crypto exchanges for enabling trading
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Ecosystem Grants
                                                </h6>
                                                Releasing grants for ecosystem development
                                            </li >
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Airdrop Release
                                                </h6>
                                                Announcement for the potential airdrop of OREVA tokens
                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image2.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H1 2027
                                                </p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Mobile Application Launch
                                                </h6>
                                                Launch of mobile application with in-built Oreva Wallet
                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Cross-chain Bridge</h6>
                                                Integrating bridge between Oreva and other blockchains

                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Oreva Connect

                                                </h6>
                                                Releasing grants for ecosystem development
                                            </li >
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Oreva Pay

                                                </h6>
                                                Releasing the retail payment application Oreva Pay


                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="testimonials_box">
                                        <div className="testimonials_image">
                                            <figure className="mb-0"><img src="images/testimonial_image2.png" alt="" className="img-fluid hover-effect" /></figure>
                                            <div className="testimonials_image_content_wrappper">
                                                <p className="person_name">H2 2027

                                                </p>
                                            </div>
                                        </div>
                                        <ul>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'> Oreva DEX
                                                </h6>
                                                Deploying the native decentralized exchange Oreva DEX



                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>NFT Marketplace (Mint) Launch
                                                </h6>
                                                Native NFT marketplace on Oreva Chain

                                            </li>
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>
                                                    Oreva Daily

                                                </h6>
                                                Launch of crypto news & PR platforms

                                            </li >
                                            <li className="testimonials_paragraph mb-0">
                                                <h6 className='person_name'>Expansion Plans

                                                </h6>
                                                Planning for further expansion in blockchain ecosystem

                                            </li>
                                        </ul>
                                        <figure className="testimonial_apostrophy_shape position-absolute mb-0"><img src="images/testimonial_apostrophy_shape.png" alt="" className="img-fluid hover-effect" /></figure>
                                    </div>
                                </div>
                            </div>
                        </Slider >
                    <figure className="testimonials_background_shape mb-0 position-absolute left_right_shape">
                        <img src="images/testimonial_background_shape.jpg" alt="" />
                    </figure>
                    <figure className="testimonials_top_shape mb-0 position-absolute top_bottom_shape">
                        <img src="images/testimonial_top_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
