import React from 'react'

export default function About() {
    return (
        <>
            <section className="aboutus_section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="aboutus_image">
                                <figure className="aboutus_main_image mb-0 left_right_shape">
                                    <img src="assets/images/oreva_coin_aboutus.png" alt="" className="img-fluid" />
                                </figure>
                                {/* <figure className="aboutus_image_shape position-absolute mb-0 left_right_shape">
                                    <img src="assets/images/oreva_coin_aboutus.png" alt="" className="img-fluid" />
                                </figure> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="aboutus_content" data-aos="fade-right">
                                <h6>OREVA Token</h6>
                                <h2>Owning OREVA Grants You All Access </h2>
                                <p>OREVA token is heart of the community which empower its holders with all the offered facility
                                </p>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        <i className="fa fa-check" aria-hidden="true" />
                                        <p>Gateway to the Oreva community</p>
                                    </li>
                                    <li>
                                        <i className="fa fa-check" aria-hidden="true" />
                                        <p>Economically strong with fixed supply</p>
                                    </li>
                                    <li>
                                        <i className="fa fa-check" aria-hidden="true" />
                                        <p>Fast, secure & low-cost transactions</p>
                                    </li>
                                </ul>
                                <div className="btn_wrapper">
                                    <a className="text-decoration-none default-btn btn-size-18 hover-effect" href="/ecosystem">Ecosystem<i className="fa-solid fa-angle-right" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <figure className="aboutus_background_shape mb-0 position-absolute left_right_shape">
                        <img src="assets/images/aboutus_background_shape.jpg" alt="" />
                    </figure>
                    <figure className="aboutus_top_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/aboutus_top_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
