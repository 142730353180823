import React from 'react'

export default function Advantages() {
    return (
        <>
            <section className="mission_vision_section position-relative">
                <div className="container">
                    <h3 className='text-white text-center mb-5'>Advantages of joining Oreva</h3>
                    <div className="row position-relative" data-aos="fade-up">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="mission_vision_box hover-effect">
                                <p className="mb-0">Daily meetups with community members who provide assistance and guidance to other members. </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="mission_vision_box hover-effect">
                                <p className="mb-0">Community trading open ups greater profitability with increased capital and efficient management.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="mission_vision_box hover-effect">
                                <p className="mb-0">Oreva connects like-mined people in a convenient way that enhances professional and personal growth. 
                                </p>
                            </div>
                        </div>
                        <figure className="mission_vision_background_shape mb-0 position-absolute left_right_shape">
                            <img src="assets/images/services_background_shape.png" alt="" className="img-fluid" />
                        </figure>
                    </div>
                    <figure className="mission_vision_left_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/mission_vision_left_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
