import React from 'react'

export default function Hero() {
    return (
        <>

            <div>
                <section className="banner-section position-relative">
                    <div className="container">
                        <div className="row justify-content-center">
                            {/* <div className="col-lg-1 col-md-1 col-sm-1 col-1 d-md-block d-none">
                                <div className="banner_best_image position-relative">
                                    <figure className="mb-0 position-absolute">
                                        <img src="assets/images/best_image.png" alt="" className="img-fluid" />
                                    </figure>
                                </div>
                            </div> */}
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="banner-section-content">
                                    <h1 data-aos="fade-up">Mutual Efforts, Mutual Benefits</h1>
                                    <div className="banner_content_wrapper position-relative pl-0 ml-0">
                                        <p className="text-white mb-0" data-aos="fade-right">A decentralized community for shared profitability</p>
                                        <figure className="mb-0 position-absolute">
                                            <img src="assets/images/banner_white_line.png" alt="" className="img-fluid" />
                                        </figure>
                                    </div>
                                    <div className="btn_wrapper pl-0 ml-0" data-aos="fade-up">
                                        <a className="text-decoration-none default-btn btn-size-18 hover-effect readmore_btn" href="">Join <i className="fa-solid fa-angle-right" /></a>
                                        <a className="text-decoration-none default-btn-two btn-size-18 hover-effect" href="">Whitepaper <i className="fa-solid fa-angle-right" /></a>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="offset-lg-1 col-lg-3 col-md-3 col-sm-12 col-12">
                                <div className="banner_image position-relative">
                                    <figure className="banner_main_image mb-0 left_right_shape">
                                        <img src="assets/images/banner_image.jpg" alt="" />
                                    </figure>
                                    <figure className="banner_image_top_shape mb-0 position-absolute">
                                        <img src="assets/images/banner_image_top_shape.png" alt="" className="img-fluid" />
                                    </figure>
                                    <figure className="banner_image_bottom_shape mb-0 position-absolute top_bottom_shape">
                                        <img src="assets/images/banner_image_bottom_shape.png" alt="" className="img-fluid" />
                                    </figure>
                                </div>
                            </div> */}
                        </div>
                        {/* <figure className="banner_left_image mb-0 position-absolute left_right_shape">
                            <img src="assets/images/banner_left_image.jpg" alt="" className="img-fluid" />
                        </figure> */}
                        <a href="#footer_section" className="top-btn">
                            <figure className="mb-0 position-absolute">
                                <img src="assets/images/scroll_down_image.png" alt="" className="img-fluid" />
                            </figure>
                        </a>
                    </div>
                </section>
                {/* <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
                    <img src="assets/images/banner_top_shape.png" alt="" className="img-fluid" />
                </figure>
                <figure className="banner_background_shape mb-0 position-absolute left_right_shape">
                    <img src="assets/images/banner_background_shape.png" alt="" />
                </figure> */}
            </div>

        </>
    )
}
