import React from 'react'

export default function Statustics() {
    return (
        <>
            <section className="statistics_section position-relative">
                <div className="container">
                    <div className="row" data-aos="fade-up">
                        {/* <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="statistics_content">
                                <h3 className="mb-0">Trusted by Thousands </h3>
                            </div>
                        </div> */}
                        
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="statistics_box hover-effect">
                                <div className="span_wrapper">
                                    <span className="number counter">20</span>
                                    <span className="plus">+</span>
                                </div>
                                <p className="text-size-16 text-white pp">Ecosystem Partners</p>
                                <p className="text-size-16 mb-0 text">Oreva partners & collaborators</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="statistics_box hover-effect">
                                <div className="span_wrapper">
                                    <span className="number counter">3</span>
                                    <span className="plus">K+</span>
                                </div>
                                <p className="text-size-16 text-white pp">Members</p>
                                <p className="text-size-16 mb-0 text">Already joined the community</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="statistics_box hover-effect">
                                <div className="span_wrapper">
                                    <span className="number counter">9</span>
                                    <span className="plus">+</span>
                                </div>
                                <p className="text-size-16 text-white pp">Products</p>
                                <p className="text-size-16 mb-0 text"> Native platforms to use OREVA token.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="statistics_box hover-effect">
                                <div className="span_wrapper"> 
                                    <span className="number counter">
                                    500
                                    </span>
                                    <span className="plus">$+</span>
                                </div>
                                <p className="text-size-16 text-white pp">Profit Goal</p>
                                <p className="text-size-16 mb-0 text">The ultimate profit <br /> goal</p>
                            </div>
                        </div>
                    </div>
                    <figure className="statistics_left_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/statistics_left_shape.png" alt="" className="img-fluid" />
                    </figure>
                    <figure className="statistics_right_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/statistics_right_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
