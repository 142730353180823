import React from 'react'

export default function Governance() {
    return (
        <>
            <section className="aboutus_section position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="aboutus_image">
                                <figure className="aboutus_main_image mb-0 left_right_shape">
                                    <img src="assets/images/oreva_coin_aboutus.png" alt="" className="img-fluid" />
                                </figure>
                                {/* <figure className="aboutus_image_shape position-absolute mb-0 left_right_shape">
                                    <img src="assets/images/oreva_coin_aboutus.png" alt="" className="img-fluid" />
                                </figure> */}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="aboutus_content" data-aos="fade-right">
                                <h2>Oreva Governance</h2>
                                <p>Oreva is governed by OREVA token holders. They can propose new changes or vote on those proposals using OREVA tokens. Oreva Governance consists of members who aim to take Oreva to the next level by continuous improvements. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <figure className="aboutus_background_shape mb-0 position-absolute left_right_shape">
                        <img src="assets/images/aboutus_background_shape.jpg" alt="" />
                    </figure>
                    <figure className="aboutus_top_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/aboutus_top_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>
        </>
    )
}
