import React from 'react'

export default function Header() {
  return (
    <>
      <header>
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light justify-content-center">
            <a className="navbar-brand" href="/"><figure className="mb-0"><img src="assets/images/oreva_logo.png" alt="" className="img-fluid" /></figure></a>
            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link" href="/about">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/ecosystem">Ecosystem</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/">Tokenomics</a>
                </li>
                {/* <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Pages </a>
                  <div className="dropdown-menu drop-down-content">
                    <ul className="list-unstyled drop-down-pages">
                      <li className="nav-item">
                        <a className="dropdown-item nav-link" href="./faqs.html">FAQ's</a>
                      </li>
                      <li className="nav-item">
                        <a className="dropdown-item nav-link" href="./projects.html">Projects</a>
                      </li>
                      <li className="nav-item">
                        <a className="dropdown-item nav-link" href="./team.html">Our Team</a>
                      </li>
                    </ul>
                  </div>
                </li> */}
                <li className="nav-item">
                  <a className="nav-link" href="/">Whitepaper</a>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link talk_btn" href="/">Let’s Talk <i className="fa-solid fa-angle-right" /></a>
                </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </header>

    </>
  )
}
