import React from 'react'

export default function Tokenomics() {
    return (
        <>
            <section className="feature_section position-relative">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="feature_content" data-aos="fade-right">
                                <h2>Tokenomics</h2>
                                {/* <p>Consectetur adipiscing elit sed do eiusmod tempor inlabore aet dolore magna aliqua ruis.</p> */}
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/certified_expert.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Token </p>
                                            <p className="text-size-16 mb-0">Oreva </p>
                                        </div>
                                    </li>
                                    <li>
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/certified_expert.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Ticker </p>
                                            <p className="text-size-16 mb-0">OREVA </p>
                                        </div>
                                    </li>
                                    <li>
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/support.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Max Supply </p>
                                            <p className="text-size-16 mb-0">70,000,000</p>
                                        </div>
                                    </li>
                                    <li>
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/support.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Decimal </p>
                                            <p className="text-size-16 mb-0">18</p>
                                        </div>
                                    </li>
                                    <li>
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/support.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Contract address </p>
                                            <p className="text-size-16 mb-0"> <a href="https://bscscan.com/address/0xb611d5de79060c34d1fe46ea8c5591b31406703b#code" target='_blank'>0xb611d5de79060c34d1fe46ea8c5591b31406703b</a> </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className="btn_wrapper">
                                        <a className="text-decoration-none default-btn btn-size-18 hover-effect" href="">Buy Now<i className="fa-solid fa-angle-right" /></a>
                                    </div>
                                    <div className="btn_wrapper">
                                        <a className="text-decoration-none default-btn btn-size-18 hover-effect" href="">Tokenomics<i className="fa-solid fa-angle-right" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="feature_image text-center">
                                <img src="assets/images/tokennomics.png" alt="" className="img-flui w-100" />

                            </div>
                        </div>
                    </div>
                    <figure className="feature_background_shape mb-0 position-absolute left_right_shape">
                        <img src="assets/images/features_background_shape.jpg" alt="" />
                    </figure>
                    <figure className="feature_top_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/features_top_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
