import React from 'react'

export default function WhoWeAre() {
  return (
    <>
      <section className="who_we_are_section position-relative" style={{backgroundImage:`url("../assets/images/how_do_we_work.jpg")`}}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="who_we_are_left_content" data-aos="fade-up">
                <h6 className="text-white">How Do We Work</h6>
                <h2 className="text-white">We Just Make It Happen</h2>
                <p>At Oreva, we combine knowledge, experience and capital from all the community members to reach greater advantages for all of us. </p>
                {/* <div className="btn_wrapper">
                  <a className="text-decoration-none default-btn-two btn-size-18 hover-effect" href="./about.html">Join Now<i className="fa-solid fa-angle-right" /></a>
                </div> */}
              </div>
            </div>
            
          </div>
          {/* <figure className="who_we_are_image position-absolute mb-0">
            <img src="assets/images/who_we_are_image.png" alt="" className="img-fluid" />
          </figure> */}
          <figure className="who_we_are_left_shape mb-0 position-absolute top_bottom_shape">
            <img src="assets/images/who_we_are_left_shape.png" alt="" className="img-fluid" />
          </figure>
          <figure className="who_we_are_right_shape mb-0 position-absolute top_bottom_shape">
            <img src="assets/images/who_we_are_right_shape.png" alt="" className="img-fluid" />
          </figure>
        </div>
      </section>


    </>
  )
}
