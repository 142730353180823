import React from 'react'
import Header from '../Comman/Header'
import EcosystemHero from './EcosystemHero'
import Product from './Product'
import Footer from '../Comman/Footer'
import Faqs from '../Home/Faqs'

export default function Ecosystem() {
  return (
    <>
      <div className="sub-banner-section-outer services-banner-section-outer position-relative">
        <Header />
        <EcosystemHero />
      </div>
      <Product/>
      <Faqs/>
      <Footer/>
    </>
  )
}
