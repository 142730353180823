import React from 'react'

export default function Community() {
    return (
        <>
            <section className="feature_section position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="feature_content" data-aos="fade-right">
                                <h2>Join the most innovative community for DeFi trading</h2>
                                <p className="text-size-16 mb-0">Oreva stands at the forefront among all community trading platforms with its novel functionalities. Its proven capabilities makes it the first choice platform for everyone in the crypto trading and investing landscape.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="feature_image">
                                <figure className="feature_main_image position-relative mb-0">
                                    <img src="assets/images/join_the_most_innovative_community.png" alt="" className="img-fluid" />
                                </figure>
                            </div>
                        </div>

                    </div>
                    <figure className="feature_background_shape mb-0 position-absolute left_right_shape">
                        <img src="assets/images/features_background_shape.jpg" alt="" />
                    </figure>
                    <figure className="feature_top_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/features_top_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>
        </>
    )
}
