import React from 'react'

export default function AboutHero() {
    return (
        <>
            <div>
                <section className="banner-section position-relative">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="banner-section-content">
                                    <h1 className="text-white" data-aos="fade-up">About Us</h1>
                                    <p className="text-white" data-aos="fade-right">Oreva leads like-minded individuals towards the horizon.</p>
                                    {/* <div className="btn_wrapper" data-aos="fade-up">
                                        <span> Home </span><i className="fa-solid fa-angles-right" aria-hidden="true" /><span className="sub_span">About</span>
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="banner_image position-relative">
                                    <figure className="banner_main_image mb-0 left_right_shape">
                                        <img src="assets/images/about_us.png" alt="" />
                                    </figure>
                                    <figure className="banner_image_bottom_shape mb-0 position-absolute top_bottom_shape">
                                        <img src="assets/images/sub_banner_image_bottom_shape.png" alt="" className="img-fluid" />
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <figure className="banner_top_shape mb-0 position-absolute top_bottom_shape">
                    <img src="assets/images/sub_banner_top_shape.png" alt="" className="img-fluid" />
                </figure>
                <figure className="banner_background_shape mb-0 position-absolute left_right_shape">
                    <img src="assets/images/sub_banner_background_shape.png" alt="" />
                </figure>
            </div>

        </>
    )
}
