import React from 'react'

export default function Service() {
    return (
        <>
            <section className="services_section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
                            <div className="services_content">
                                <h6>Who Could Join Community?</h6>
                                <h2>Oreva is open for all</h2>
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12 col-12 d-md-block d-none" />
                    </div>
                    <div className="row position-relative" data-aos="fade-up">
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box hover-effect">
                                <figure>
                                    <img src="assets/images/investors.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Investors </h5>
                                <p>Oreva welcomes every investor regardless of capital allotment or investment background.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box2 hover-effect">
                                <figure>
                                    <img src="assets/images/traders.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Traders </h5>
                                <p>Traders in Oreva play a vital role as they employ and execute fruitful trading strategies.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                            <div className="services_box box3 hover-effect">
                                <figure>
                                    <img src="assets/images/leaders.png" alt="" className="img-fluid hover-effect" />
                                </figure>
                                <h5>Leaders </h5>
                                <p>Leaders bring proven management skills and experience to the global Oreva community.</p>
                            </div>
                        </div>
                        <figure className="services_background_shape mb-0 position-absolute left_right_shape">
                            <img src="assets/images/services_background_shape.png" alt="" className="img-fluid" />
                        </figure>
                    </div>
                    <figure className="services_right_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/services_right_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
