import logo from './logo.svg';
import './App.css';
import Index from './Components/Home/Index';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from './Components/About/About';
import Ecosystem from './Components/Ecosystem/Ecosystem';

function App() {
  return (
    <>
      {/* <Index /> */}
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/about" element={<About />} />
        <Route path="/ecosystem" element={<Ecosystem />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
