import React from 'react'

export default function Footer() {
  return (
    <>
      <section className="footer-section" id="footer_section">
        <div className="container">
          <div className="middle-portion">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <div className="about_col">
                  <a href="/">
                    <figure>
                      <img src="assets/images/oreva_logo.png" alt="" className="img-fluid" />
                    </figure>
                  </a>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <p className="text-size-16">Oreva is a community trading platform where people meet & exchange their ideas, knowledge & experiences.</p>
                    </li>
                    <li className="contact_mail text-gold">
                      <i className="fa-sharp fa-solid fa-envelope" />
                      <a href="mailto:info@orevacoin.io" className="text-decoration-none text-gold"> info@orevacoin.io</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-3 col-xs-12 d-md-block d-none">
                <div className="links_col">
                  <h4>Useful Links</h4>
                  <ul className="list-unstyled mb-0">
                    <li>
                      <span />
                      <a href="/about">About</a>
                    </li>
                    <li>
                      <span />
                      <a href="/ecosystem">Ecosystem</a>
                    </li>
                    <li>
                      <span />
                      <a href="/">Tokenomics</a>
                    </li>
                    <li>
                      <span />
                      <a href="/">Whitepaper</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <div className="footer_right_shape top_bottom_shape">
            <figure className="mb-0"><img src="assets/images/footer_right_shape.png" alt="" className="img-fluid" /></figure>
          </div>
          <div className="footer_background_shape left_right_shape">
            <figure className="mb-0"><img src="assets/images/footer_background_shape.jpg" alt="" /></figure>
          </div>
        </div>
        <div className="bottom-portion">
          <div className="copyright col-xl-12">
            <p>Copyright © 2023 Oreva All rights reserved.</p>
          </div>
        </div>
      </section>

    </>
  )
}
