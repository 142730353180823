import React from 'react'

export default function Empowering() {
    return (
        <>
            <section className="feature_section position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="feature_image">
                                <figure className="feature_main_image position-relative mb-0">
                                    <img src="assets/images/join_us.png" alt="" className="img-fluid" />
                                </figure>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                            <div className="feature_content" data-aos="fade-right">
                                {/* <h6>FEATURES</h6>s */}
                                <h2>Why You Should <br /> Join Us</h2>
                                <p>Oreva is an open platform that empowers every member to grow financially, socially and personally.
                                </p>
                                <ul className="list-unstyled mb-0">
                                    <li>
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/certified_expert.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Fully Automated & Decentralized </p>
                                            <p className="text-size-16 mb-0">Oreva works in fully automated flow with no central entity controlling operations.
                                            </p>
                                        </div>
                                    </li>
                                    <li >
                                        {/* <figure className="mb-0">
                                            <img src="assets/images/support.png" alt="" className="img-fluid" />
                                        </figure> */}
                                        <div className="feature_content_wrapper">
                                            <p className="first_p">Blockchain-based Operations </p>
                                            <p className="text-size-16 mb-0">Oreva only requires you to share your blockchain wallet only, nothing more to verify your identity. </p>
                                        </div>
                                    </li>
                                </ul>
                                {/* <div className="btn_wrapper">
                                    <a className="text-decoration-none default-btn btn-size-18 hover-effect" href="">Join<i className="fa-solid fa-angle-right" /></a>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <figure className="feature_background_shape mb-0 position-absolute left_right_shape">
                        <img src="assets/images/features_background_shape.jpg" alt="" />
                    </figure>
                    <figure className="feature_top_shape mb-0 position-absolute top_bottom_shape">
                        <img src="assets/images/features_top_shape.png" alt="" className="img-fluid" />
                    </figure>
                </div>
            </section>

        </>
    )
}
